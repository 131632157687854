<template>
  <div class="">
    <div class="website-header-box">
      <div class="new_header_box"
           :class="{
          'user_demo_show':userDomeShow || mobileShow,
          'morePopShow':mobilePopShow
        }">
        <div class="">
          <div class="website-header"
               style="height: 72px">
            <div class="left">
              <i class="yzg-logo"></i>
              <router-link to="/"
                           target='_blank'
                           class="theme-a">住宿业数字化营销管家</router-link>
            </div>
            <div class="content">
              <router-link to="/yzgInstructions"
                           class="init_a_gray mr32 aboutUs_a"
                           target="_blank">为什么选择云掌柜</router-link>
<!--              <a href="https://vip.yunzhanggui.net/Public/college"-->
<!--                 class="init_a_gray mr32 aboutUs_a"-->
<!--                 target="_blank">民宿学苑</a>-->
              <router-link to="/college" class="init_a_gray mr32 aboutUs_a" target="_blank">民宿学苑</router-link>
              <!-- -->
              <!--<div class="web-demo hover_box web-demo-show"-->
              <!--id="userDemo"-->
              <!--@mouseenter='userDomeShow= true;mobileShow= false'-->
              <!--@mouseleave='userDomeShow= false'>-->
              <div class="web-demo hover_box web-demo-show"
                   id="userDemo">
                <!-- <span class="init_a_gray">客户案例</span>
                <i class="icon_selected"></i> -->
                <router-link :to="{path:'/userDemo',query:{title: '客户案例'}}"
                             class="init_a_gray"
                             target="_blank">客户案例</router-link>
                <!--<i class="icon_selected"></i>-->
                <!--<div class="hover_selected_block">-->
                <!--<i class="icon_act_selected"></i>-->
                <!--</div>-->
              </div>
              <div class="web-demo ml32 aboutUs_a">
                <router-link :to="{path:'/aboutUs',query:{id:0}}"
                             class="init_a_gray"
                             target='_blank'>关于我们</router-link>
              </div>
              <div class="web-demo ml28 hover_box web-demo-show"
                   id="userApp"
                   @mouseenter="mobileShow = true;userDomeShow= false"
                   @mouseleave="mobileShow = false">
                <span class="init_a_gray">移动端</span>
                <i class="icon_selected"></i>
                <div class="hover_selected_block">
                  <i class="icon_act_selected"></i>
                </div>
              </div>
            </div>
            <div class="right"
                 id="morePop"
                 @click="handleMobileShow">
              <a href="https://v2.yunzhanggui.net/user/login"
                 class="init_a_gray the-trial"
                 target="_blank">免费试用</a>
              <a href="https://v2.yunzhanggui.net/user/login"
                 class="yzg_logo_button ml32"
                 target="_blank">登录</a>
            </div>
          </div>
        </div>
        <div class="user_demo_box"
             v-if="userDomeShow"
             @mouseenter="userDomeShow=true"
             @mouseleave='userDomeShow= false'>
          <div class="f-r-c-s">
            <ul class="demo_list"
                id="demoList">
              <li v-for="(item,index) in userDemoList"
                  :key="index">
                <div class="f-c-s-c"
                     style="height: 100%;"
                     @click="routerDemo(item)">
                  <div class="relative f-r-s-c">
                    <img :src="item.bgImage"
                         alt=""
                         class='demo_img'>
                    <img :src="item.logoImage"
                         alt=""
                         class="demo-logo">
                  </div>
                  <div class="inn_name_bottom_box">
                    <p class="user_inn_name">{{item.userDemotitle}}</p>
                    <p class="user_inn_desc">{{item.userDemoDesc}}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="yzg_qrCode_box"
             v-if="mobileShow"
             @mouseenter="mobileShow=true"
             @mouseleave='mobileShow= false'>
          <div class="f-r-c-c"
               style="height: 100%;">
            <div class="f-r-c-e">
              <div class="code_left">
                <p class="tr">随时随地管理民宿</p>
                <p>你可以在电脑、手机和平板设备使用云掌柜管理民宿</p>
              </div>
              <div style="display: flex;">
                <div class="relative"
                     style="height: 128px;">
                  <img src="../assets/pic/icon_guoneiban.jpg"
                       alt=""
                       style="width:128px;height:128px">
                  <p class="tc code_text">微信小程序</p>
                </div>
                <div class="ml32 relative"
                     style="height: 128px;">
                  <img src="../assets/pic/icon_guojiban.png"
                       alt=""
                       style="width:128px;height:128px">
                  <p class="tc code_text">移动应用（app）</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="morePopHtml">
          <div class="borderItem">
            <div class="morePopHtmlItem">
              <a href="./yzgInstructions.html"
                 class="init_a_gray">为什么选择云掌柜</a>
            </div>
          </div>
          <div class="borderItem">
            <div class="morePopHtmlItem init_a_gray">
              <router-link to="/college" class="init_a_gray mr32 aboutUs_a" target="_blank">民宿学苑</router-link>
            </div>
          </div>
          <div class="borderItem">
            <div class="morePopHtmlItem demoSildeShow"
                 :class="{
                'icon_selected_arrow_top':mobileUserShow
              }">
              <router-link :to="{path:'/userDemo',query:{title: '客户案例'}}"
                           class="init_a_gray"
                           target="_blank">客户案例</router-link>
            </div>
          </div>
          <div class="borderItem">
            <div class="morePopHtmlItem">
              <router-link :to="{path:'/aboutUs',query:{id:0}}"
                           class="init_a_gray">关于我们</router-link>
            </div>
          </div>
          <div class="borderItem"
               @click="handleMobileUserShow('mobile')">
            <div class="morePopHtmlItem demoSildeShow"
                 :class="{
                'icon_selected_arrow_top':mobileOtherShow
              }">
              <p class="title">移动端</p>
              <i class="icon_selected_arrow"></i>
            </div>
            <div class="app_user_demo_show"
                 :class="{
                'otherShow':mobileOtherShow
              }">
              <div class="app_code_top">
                <p class="tc">随时随地管理民宿</p>
                <p class="tc">你可以在电脑、手机和平板设备使用云掌柜管理民宿</p>
              </div>
              <div class="f-c-c-c mt24"
                   style="height:auto">
                <img src="../assets/pic/icon_guoneiban.jpg"
                     alt=""
                     style="height:128px;">
                <span class="mt12">微信小程序</span>
              </div>
              <div class="f-c-c-c mt24 mb24"
                   style="height:auto">
                <img src="../assets/pic/icon_guojiban.png"
                     alt=""
                     style="height:128px;">
                <span class="mt12">移动应用（app）</span>
              </div>
            </div>
          </div>

          <a href="https://v2.yunzhanggui.net/user/login"
             class="morePopHtmlItemTitle">免费试用</a>
          <a href="https://v2.yunzhanggui.net/user/login"
             class="loginButton">登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      userDomeShow: false,
      mobileShow: false,
      mobilePopShow: false,
      mobileUserShow: false,
      mobileOtherShow: false,
      userDemoList: [
        {
          bgImage: require('@/assets/pic/index-zhulanjiuba.png'),
          logoImage: require('@/assets/pic/demo_zhulanjiuba.png'),
          userDemotitle: '​猪栏酒吧',
          userDemoDesc: '用预售提高全年入住率​​',
          id: 1,
          title: "客户案例-猪栏酒吧"
        }
        ,
        {
          bgImage: require('@/assets/pic/index-fuyunmuchang.png'),
          logoImage: require('@/assets/pic/demo_fuyunmuchang.png'),
          userDemotitle: '浮云牧场',
          userDemoDesc: '用云掌柜轻松实现连锁经营',
          id: 2,
          title: "客户案例-浮云牧场"
        }
        ,
        {
          bgImage: require('@/assets/pic/index-huluhai.png'),
          logoImage: require('@/assets/pic/demo_huluhai.png'),
          userDemotitle: '​呼噜海',
          userDemoDesc: '用小程序发展直客营销',
          id: 3,
          title: "客户案例-呼噜海"
        }
        ,
        {
          bgImage: require('@/assets/pic/index-daliyun.png'),
          logoImage: require('@/assets/pic/demo_yunduan.png'),
          userDemotitle: '大理云端连锁',
          userDemoDesc: '用渠道直连轻松管理多渠道订单​​',
          id: 4,
          title: "客户案例-大理云端连锁"
        }
      ]
    };
  },
  methods: {
    handleShow () {
      this.userDomeShow = !this.userDomeShow
    },
    handleMobileShow () {
      this.mobilePopShow = !this.mobilePopShow
    },
    handleMobileUserShow (type) {
      switch (type) {
        case 'user':
          this.mobileUserShow = !this.mobileUserShow
          break;
        case 'mobile':
          this.mobileOtherShow = !this.mobileOtherShow
          break;
      }

      // this.mobileUserShow = !this.mobileUserShow
    },
    routerDemo (type) {
      console.log(type, '冲冲冲');
      // this.$router.push({
      //   id:type.id,
      // })
      let routerUrl = this.$router.resolve({
        path: '/userDemo',
        query: {
          id: type.id,
          title: type.title
        }
      })
      window.open(routerUrl.href, '_blank')
    }
  },
  computed: {},
  props: {},
  watch: {},
  created () { },
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.demo-logo {
  border-radius: 50%;
}
</style>
