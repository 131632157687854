<template>
<div class='' v-if="visible">
  <div class="privacyPop">
    <div class="mask"></div>
    <div class="yzg_website_pop">
      <div class="pop_header">
        <p class="left"></p>
        <p class="title">隐私条款</p>
        <i class="pop_close mr24" @click="handleClose"></i>
      </div>
      <div class="pop_content">
        <div class="privacy_text">
          <p>感谢您使用云掌柜的产品和服务。您在使用我们的服务时，我们将按照云掌柜用户隐私政策（以下简称“本政策”）收集、存储、使用及对外提供您的信息。请您在使用我们的服务前仔细阅读并理解本政策全部内容。</p>
          <p>
          信息的收集
          <br>
          在您使用本公司服务的过程中，本公司会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我们的服务以及保障您的账户安全：
          <br>
          1、您在注册账户时填写的信息，如：手机号码、短信验证码、密码信息。
          <br>
          2、您在使用服务时上传的信息，如：您的日志行为（账户信息、用户会话、访问时间、访问页面、客户端IP地址信息），以及终端信息（设备型号、操作系统、设备标识、浏览器信息）。
          <br>
          3、从第三方合作伙伴获取的信息，如直连OTA获得的服务名称、登录时间等。请仔细阅读第三方合作伙伴服务的用户协议或隐私政策。
          </p>
          <p>
          信息的使用
          <br>
          我们将按照以下方式使用我们收集的信息：
          <br>
          1、向您提供服务，您的信息可能会用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；
          <br>
          2、产品开发或帮助我们评估、改善或设计服务及运营活动；
          <br>
          3、安全保障，本公司不会向任何无关第三方提供您的个人信息，除非获得您的许可，或联合第三方共同向您提供服务；
          </p>
          <p>
          Cookie及相关技术的使用
          <br>
          您访问我们的网站或使用提供的服务时，我们可能通过放置安全的Cookie及相关技术收集您的信息，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。您有权拒绝我们的Cookie。您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据。但这样做可能会影响您的访问安全以及使用我们提供的服务。
          </p>
          <p>
          信息的对外提供
          <br>
          1、我们遵循法律法规的规定，对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息：
          <br>
          （1）事先经过您的同意。
          <br>
          （2）仅为实现外部处理的目的，包括我们的关联公司、第三方合作伙伴等。在与第三方合作伙伴分享您的信息之前，我们会对您的信息进行匿名化处理；同时要求该第三方让他们按照我们的说明、隐私政策以及其他法律法规与监管要求对您的信息进行保护。
          <br>
          （3）我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的城市热力图或行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。
          <br>
          2、除此之外，我们还可能基于以下目的披露您的信息：
          <br>
          （1）遵守法院判决、裁定或其他法律程序的规定；
            <br>
          （2）遵守相关政府机关或其他法定授权组织的要求；
            <br>
          （3）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
            <br>
          （4）为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。
          </p>
          <p>
          信息安全
          <br>
          1、我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。
          <br>
          2、我们严格遵守法律法规保护用户的通信秘密。
          <br>
          3、我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。
          <br>
          4、我们建立专门的管理制度、流程和组织确保信息安全。
          <br>
          5、若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
          <br>
          6、我们通过不断提升的技术手段加强安装在您设备端的软件的安全能力，以防止您的个人信息泄露。
          <br>
          7、我们会尽力保护您的个人信息。我们也请您理解，任何安全措施都无法做到无懈可击。
          </p>
          <p>
          信息的管理
          <br>
          1、您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。
          <br>
          2、我们将按照本政策所述，仅为实现我们产品或服务的功能，收集、使用您的信息。
          <br>
          3、在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户的安全。
          <br>
          4、请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。
          </p>
          <p>
          本政策的使用及更新
          本政策适用本公司提供的相关产品或服务，包括web、手机客户端等版本产品及服务。如本政策发生更新，我们将以APP推送通知、弹窗提示、发送邮件/短消息或者在我们的官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。变更后的政策内容在公示届满7日起生效，如您在本政策更新生效后继续使用我们的服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import eventBus from "../eventBus"
export default {
components: {},
data() {
return {
  visible:false
};
},
methods: {
  handleClose(){
    this.visible = false
    this.$emit('closePrivacyPop',false)
  }
},
computed: {},
props:{},
watch: {},
created() {

},
mounted() {
  eventBus.$on('privacyShow',val=>this.visible = val)
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {
  eventBus.$off('privacyShow')
}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

</style>