<template>
<div class='' v-if="visible">
  <div class="servePop">
    <div class="mask"></div>
    <div class="yzg_website_pop">
      <div class="pop_header">
        <p class="left"></p>
        <p class="title">服务条款</p>
        <i class="pop_close mr24" @click="closePop"></i>
      </div>
      <div class="pop_content">
        <div class="privacy_text">
          <p>

          欢迎您使用云掌柜的产品与服务。请您务必仔细阅读并理解《用户服务协议》（以下简称“本协议”），本协议是您与北京米天下科技股份有限公司（以下简称“本公司”）就您使用云掌柜产品及服务（以下称“本服务”）所订立的协议。本协议对您和本公司均具有法律约束力。
          </p>
          <p>
          除非您已阅读并接受本服务协议所有条款，否则您无权下载、注册和使用本产品和相关服务。如您点“同意”、“注册”和“登录”等行为或实际使用本服务即视为您已阅读并同意本协议的约束。请您审慎阅读并选择是否接受本协议，如果您对本协议有任何疑问，应向客服咨询。
          </p>
          <p>
          一、协议的主体与范围
          1、【签约主体】视您所使用的具体服务而定，您可在相关服务的具体服务条款或说明文件中查看上述主体的名称和信息。“用户”是指使用云掌柜产品和服务的使用人，在本服务协议中更多称呼为“您”。
          </p>
          <p>
          2、【协议内容】本服务协议内容同时包括：
          （1）本协议内容同时包括《隐私政策》及所有云掌柜平台已经发布或未来可能发布的各类规则、公告或通知，一经发布即为本协议不可分割的组成部分，您同样应当遵守。如您不同意本协议的约定，您应立即停止注册并停止使用云掌柜的相关产品和服务。
          （2）第三方服务相关的用户授权协议。您可根据自身需要，通过云掌柜产品进行跳转等方式，自愿选择接受独立第三方服务商及部分关联第三方服务商所提供的服务，您将在使用该第三方服务前对相应的用户授权协议予以同意确认，以作为您和本公司之间就该等第三方服务的使用所涉及的相关事项的权利义务依据。
          （3）本公司有权更新用户协议、隐私权政策及相关平台规则（统称“用户协议”），届时协议更新会通过系统提示、信息推送通知和提醒您注意。您可以在相关服务页面查阅最新版本的条款。用户协议变更后，如您继使用本公司提供的服务，即表示您同意更新后的用户协议。如您不同意变更后的用户协议，请您停止使用产品和服务。
          </p>
          <p>
          3、上述各项内容之间如存在不一致之处，以时间上最新发布的内容为准。
          </p>
          <p>
          二、注册
          </p>
          <p>
          1、用户资格：您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。如果您不具备上述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。
          </p>
          <p>
          2、注册资料：用户应如实提交相关注册资料，不可提交任何违法或不良信息，相关资料如有变动，您应及时更新。本公司并不对您提交的资料及信息进行实质审查。如果因您所提供的信息不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将独立承担相应的法律责任及不利后果，并且本公司有权终止您使用相关产品和服务。
          </p>
          <p>
          3、信息保护：云掌柜将对您提交的注册信息予以保护，除非得到用户的授权或法律规定，本公司不会将其披露给任何非关联的第三方。
          </p>
          <p>
          三、账户的使用
          </p>
          <p>
          1、账户获得：云掌柜账户的所有权归本公司所有。当您按照注册页提示内容填写信息、阅读并同意本协议后即可获得云掌柜账户的使用权。
          </p>
          <p>
          2、账户使用：您的账户仅限您本人使用，并由您独立维护、运营并独立承担全部责任。该账户下的所有行为（包括但不限于上传、发布、购买等行为）将视为您本人的真实意愿，并且您对其产生的一切后果负责。
          </p>
          <p>
          3、账户转让：由于用户账户关联用户信息，仅当有法律明文规定、司法裁定或符合我方平台规则规定的用户账户转让流程的情况下，您才可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则由此产生的一切责任均由您承担。
          </p>
          <p>
          4、账户保管：您的账户密码由您自行设定。您应妥善保管您的账户和密码。因您保管不善可能导致账户被他人使用（包括但不限于遭受盗号、密码失窃）或信息数据泄漏，责任由您自行承担。在您未明确告知本公司账户被他人使用或信息数据泄漏等情况并提供相关证明材料前，本公司有理由相信该账户行为是您使用账户的行为或得到了您的充分授权。
          </p>
          <p>
          5、账户终止：为了充分利用账户资源，如果您存在长期未登陆使用账户等情形，本公司有权终止您对该账户的使用。
          </p>
          <p>
          四、用户信息的合理使用
          <br>
          1、您知悉并认可：云掌柜平台有权向您提供的手机号、邮件、地址邮寄、站内信息或其他方式发送必要的信息。
          </p>
          <p>
          2、您在使用我方产品或服务提交的个人信息，我方会按照《隐私政策》收集、存储、使用、披露和保护。如果您不同意《隐私政策》的任何内容，您应立即停止使用本服务。
          </p>
          <p>
          3、未经本公司允许，您不应向任何第三方披露通过本服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息；否则，由此产生的后果由您自行承担。
          </p>
          <p>
          五、数据管理
          <br>
          1、 本公司不对您在使用本服务过程中产生数据的删除或储存失败负责。
          </p>
          <p>
          2、本公司有权根据实际情况自行决定单个用户数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本服务中的相关数据。
          </p>
          <p>
          3、如果您停止使用本服务或服务被终止或取消，本公司可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，本公司没有义务向您返还任何数据。
          </p>
          <p>
          4、除本服务为保障数据安全而采取的各类安全技术措施之外，您应自行对因使用本服务而存储在本公司服务器的各类数据采取合理、安全的技术措施，确保其安全性，并对自己的行为所引起的结果承担全部责任。
          </p>
          <p>
          5、您应合理使用本服务过程中收集、储存、形成的数据；未经本公司同意，您不得向任何第三方披露使用本服务过程中收集、储存、形成的数据；否则相关责任应当由您个人承担。
          </p>
          <p>
          六、行为规范
          <br>
          1、您不得利用本服务进行如下行为：
          <br>
          （1）提交、发布虚假信息；
          <br>
          （2）虚构事实、隐瞒真相以误导、欺骗他人的；
          <br>
          （3）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
          <br>
          （4）违反功能限制或运营策略，或采取任何措施规避前述限制或策略，干扰本服务正常运营；
          <br>
          （5）未经本公司许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的；
          <br>
          （6）利用本服务从事包括但不限于欺诈、传销、违法物品营销等任何违法犯罪活动的；
          <br>
          （7）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播；
          <br>
          （8）任何导致或可能导致本公司与第三方产生纠纷、争议或诉讼的行为。
          </p>
          <p>
          2、您在使用本服务的过程中，不得从事包括但不限于以下行为，也不得为以下行为提供便利（包括但不限于为用户的行为提供便利等）：
          <br>
          （1）以任何方式干扰或企图干扰本公司任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；
          <br>
          （2）未经本公司许可，以本公司名义使用任何本公司商标标识或其任何变体、缩写、改写等；
          <br>
          （3）未经本公司许可，以任何方式使用本公司URL地址、技术接口等；
          <br>
          （4）擅自以本公司的名义向第三方发布、发送商业广告等信息；
          <br>
          （5）未经授权获取对本公司产品或服务的访问权；
          <br>
          （6）捏造或虚构事实，或未经本公司书面同意，公开表达或暗示，您与本公司之间存在合作关系，包括但不限于相互持股、商业往来或合作关系等，或声称本公司对您的认可；
          <br>
          （7）其他本公司认为不应该、不适当的行为、内容。
          </p>
          <p>
          3、如您违反相关法律法规、本协议以及其他规则的规定，或本公司发现或收到他人投诉您违反本协议约定的，本公司有权限制、冻结或终止您对账户的使用并停止向您提供服务，且根据实际情况决定是否恢复使用。由此给您带来的损失由您自行承担。
          </p>
          <p>
          4、因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；本公司因此遭受损失的，您也应当一并赔偿。
          </p>
          <p>
          5、为及时保障用户合法权益不受侵害，若您的账户存在权益纠纷，本公司有权根据情况，采取包括但不限于暂停、冻结该账户的部分或全部功能等保护措施，直至有权机关作出生效裁判或用户在不违反本协议的前提下协商一致，并由此所产生的一切法律责任均与本公司无关。
          </p>
          <p>
          七、其他
          <br>
          1、本公司部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的专项规则。
          </p>
          <p>
          2、本公司可能根据实际需要对收费服务的收费标准、方式进行修改和变更，本公司也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，本公司将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
          </p>
          <p>
          3、在使用本服务时，您须自行承担如下本公司不可掌控的风险内容，包括但不限于：
          <br>
          （1）由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等损失和风险；
          <br>
          （2）用户或本公司的电脑软件、系统、硬件和通信线路出现故障导致的服务终端、数据丢失以及其他的损失和风险；
          <br>
          （3）用户操作不当或通过非本公司授权的方式使用本服务带来的损失和风险；
          <br>
          （4）用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；
          <br>
          （5）由于网络信号不稳定等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险；
          <br>
          （6）其他本公司无法控制或合理预见的情形。
          </p>
          <p>
          4、因业务发展需要，本公司保留单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，用户需承担此风险。
          </p>
          <p>
          5、在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴等以及社会事件如战争、动乱、政府行为等。
          </p>
          <p>
          6、本平台内的所有内容（用户依法享有版权的内容除外）、技术、软件、程序、数据及其他信息的所有知识产权及相关权利，均归本公司所有。未经本公司许可，任何人不得擅自使用。
          </p>
          <p>
          7、本协议签订地为中华人民共和国上海市浦东新区。
          </p>
          <p>
          8、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。
          </p>
          <p>
          9、若您和本公司之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
          </p>
          <p>
          10、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
          </p>
          <p>
          11、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
          </p>
          <p>
          北京米天下科技股份有限公司
          </p>
          <p>
            2020年10月30日修订
          </p>
          
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import eventBus from "../eventBus"
export default {
components: {},
data() {
return {
  visible: false
};
},
methods: {
  closePop(){
    this.visible = false
    this.$emit('closeServePop',false)
  }
},
computed: {},
props:{},
watch: {},
created() {

},
mounted() {
  eventBus.$on('serveShow',val=>this.visible = val)
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

</style>