import { render, staticRenderFns } from "./YzgHeader.vue?vue&type=template&id=360e5b71&scoped=true&"
import script from "./YzgHeader.vue?vue&type=script&lang=js&"
export * from "./YzgHeader.vue?vue&type=script&lang=js&"
import style0 from "./YzgHeader.vue?vue&type=style&index=0&id=360e5b71&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360e5b71",
  null
  
)

export default component.exports